import React, { lazy, Suspense } from 'react';
import { Route, HashRouter } from 'react-router-dom';

import { CombinedContextProvider } from './contexts/CombinationContext';

const App = lazy(() => import('./containers/App'));

const RootRouter = () => {
  return (
    <CombinedContextProvider>
      <HashRouter>
        <Suspense
          fallback={
            <div className="preloader">
              <div className="loader">
                <div className="css-loader" />
              </div>
            </div>
          }
        >
          <Route component={App} />
        </Suspense>
      </HashRouter>
    </CombinedContextProvider>
  )
}

export default RootRouter;