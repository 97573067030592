/* eslint-disable */
import React from 'react';

import VoucherProvider, { VoucherConsumer } from './VoucherContext/voucherContext';


const CombinedContextProvider = ({ children }) => (
  <VoucherProvider>
    {children}
  </VoucherProvider>
);

const CombineContextConsumer = ({ children, props }) => {
  return (
    <VoucherConsumer>
      {(voucherContext) => {
        return children({
          ...voucherContext
        })
      }}
    </VoucherConsumer>
  )
};

export { CombinedContextProvider, CombineContextConsumer };


