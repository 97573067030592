/* eslint-disable func-names */
// @flow
import Themes from '../utils/themes';

const LocalStorageService = (function() {
  let service;

  function getService() {
    if (!service) {
      service = this;
      return service;
    }
    return service;
  }

  function setUserRef(userRef) {
    localStorage.setItem('USER_REF', userRef);
  }

  function getUserRef() {
    return localStorage.getItem('USER_REF');
  }

  function setShopRef(userRef) {
    localStorage.setItem('SHOP_REF', userRef);
  }

  function getShopRef() {
    return localStorage.getItem('SHOP_REF');
  }

  function setTheme(theme: Object) {
    localStorage.setItem('THEME', JSON.stringify(theme));
  }

  function getTheme() {
    return localStorage.getItem('THEME') || JSON.stringify(Themes.default);
  }

  return {
    getService,
    setUserRef,
    getUserRef,
    setShopRef,
    getShopRef,
    setTheme,
    getTheme
  }
})();

export default LocalStorageService;


