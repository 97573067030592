const themes = {
  default: {
    '--main-color': '#98694b',
    '--loading-color': '#af8e56',
    '--header-nav-background': '#98694b',
    '--voucher-status-active-button-background': '#e5dbd5',
    '--voucher-status-button-background': '#ffffff',
    '--voucher-status-button-hover': 'rgba(229, 219, 213, 0.3)'
  }
}

export default themes;