/* eslint-disable no-param-reassign, consistent-return, no-unused-vars */
// @flow
import axios from 'axios';
import { toast } from 'react-toastify';

import { BASE_URL } from '../utils/constants';


axios.defaults.baseURL = BASE_URL;
let refreshError = false;

const axiosApiInstance = axios.create();

const parseBody = (response) => {
  if (response.status === 200) {
    return response.data;
  }
  return this.parseError(response.data.messages);
};

axiosApiInstance.interceptors.request.use(
  (config) => {
    config.headers = {
      'Content-Type': 'application/json',
      'Accept-Language': 'en-us'
    };
    refreshError = false;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string = '') => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axiosApiInstance.interceptors.response.use(
  (response) => {
    return parseBody(response);
  },
  (error) => {
    console.log('e', error);
    if (!error.response) {
      toast.error('Server error. Please try again later');
      return;
    }
    const originalRequest = error.config;
    if (
      originalRequest &&
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((authToken) => {
            originalRequest.headers.Authorization = `Bearer ${authToken}`;
            return axiosApiInstance.request(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
      originalRequest._retry = true;
      isRefreshing = true;

      const config = {
        headers: {
          'Content-Type': 'application / json',
          Accept: 'application/json',
        },
      };


    }
    return Promise.reject(error);
  }
);

export default axiosApiInstance;
