/* eslint-disable react/no-unused-state, react/state-in-constructor */
// @flow
import React from 'react';
import { toast } from 'react-toastify';

import LocalStorageService from '../../services/localstorageservice';
import { getVouchers, getVoucherDetail } from '../../controllers/voucherController';
// import { getOfferCategoryValue} from '../../utils/helpers';

const localStorageService = LocalStorageService.getService();
const { Provider, Consumer } = React.createContext < any > ();

export default class VoucherProvider extends React.Component<Props, State> {
  state = {
    isVoucherLoading: false,
    isVoucherDetailLoading: false,
    vouchers: [],
    voucher: null,
    isError: false,
    getVouchersListing: async (skip, take, status) => {
      if (!localStorageService.getUserRef()) {
        toast.error("Missing member info");
        return;
      }
      this.setState({isVoucherLoading: true});
      try {
        const result = await getVouchers(skip, take, status);
        // const keys = result.
        this.setState({ isVoucherLoading: false, vouchers: result, isError: false });
      } catch (e) {
        this.setState({ isVoucherLoading: false, isError: true });
      }
    },
    getVoucherDetail: async(voucherCode) => {
      if (!localStorageService.getUserRef()) {
        toast.error("Missing member info");
        return;
      }
      this.setState({ isVoucherDetailLoading: true });
      try {
        const result = await getVoucherDetail(voucherCode);
        this.setState({ isVoucherDetailLoading: false, voucher: result });
      } catch (e) {
        this.setState({ isVoucherDetailLoading: false });
      }
    },
    selectVoucher: (voucherSelected) => {
      this.setState({ voucher: voucherSelected})
    }
  }

  render() {
    const { children } = this.props;
    return <Provider value={this.state}>{children}</Provider>;
  }
}

export const VoucherConsumer = (props: Props) => {
  const { children } = props;
  return <Consumer>{children}</Consumer>;
};