/* eslint-disable import/prefer-default-export, no-console */
// @flow
import { toast } from 'react-toastify';

import axiosApiInstance from '../services/tokeninterceptorservice';
import LocalStorageService from '../services/localstorageservice';

const localStorageService = LocalStorageService.getService();
const endpoint = '/api/integration/v1/voucher';

const getVouchers = async (skip, take, status) => {
  try {
    let userRef = localStorageService.getUserRef();
    if (localStorageService.getUserRef().slice(-1) === ">") {
      userRef = `${localStorageService.getUserRef().slice(0, -1)}*`;
    }
    const output = await axiosApiInstance.get(
      `${endpoint}?skip=${skip}&take=${take}&userRef=${userRef}&status=${status}`
    );
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
}

const getVoucherDetail = async (voucherCode) => {
  try {
    let userRef = localStorageService.getUserRef();
    if (localStorageService.getUserRef().slice(-1) === ">") {
      userRef = `${localStorageService.getUserRef().slice(0, -1)}*`;
    }
    const output = await axiosApiInstance.get(
      `${endpoint}/voucherdetail?voucherCode=${voucherCode}&userRef=${userRef}`
    );
    return output;
  } catch (e) {
    toast.error(e.response.data.errorMessage);
    throw e;
  }
}

export { getVouchers, getVoucherDetail }